@import '../../global.sass'

.content
  @extend .padding-x
  margin-top: 30px
  display: grid
  h2
    text-align: center
    border-bottom: 2px solid lighten($primary, 30)
    width: 80%
  ul
    display: flex
    flex-direction: column
    gap: 10px
  &--primary
    color: white
    background-color: $primary
  section
    display: grid
    justify-items: center
    align-items: center
    padding: 30px 0
  .top
    align-items: flex-start
  .left
    justify-items: flex-start
  .col2
    gap: 30px
    grid-template-columns: 1fr 1px 1fr
    @media screen and (max-width: $media-small)
      grid-template-columns: 1fr
      grid-template-rows: auto 1px 1fr
    .line
      background-color: #000000
      height: 100%
      width: 100%
    div
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      color: white
    .black
      color: black
    img
      width: 50%
      max-width: 350px
    p
      font-size: 24px
      font-weight: bold
  .left-top-rounded
    width: 100%
    height: 100%
    padding: 30px
    background-color: $primary
    border-radius: 30px 0 0 0
  .right-bottom-rounded
    width: 100%
    height: 100%
    padding: 30px
    background-color: $primary
    color: white
    text-align: center
    font-size: 24px
    font-weight: bold
    border-radius: 0 0 30px 0
  .heading
    font-size: 24px
    font-weight: bold
    text-align: center
    margin-bottom: 30px
  .products
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: flex-end
    gap: 30px
  .blue
    color: $primary
  